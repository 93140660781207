<template>
  <div class="d-sm-flex justify-content-start align-items-center">
    <div class="btn-wrap">
      <button
        v-if="actions.includes('reFresh')"
        class="compact ui button text-height"
        @click="onRefreshPage"
      >
        <refresh-cw-icon
          size="12"
          class="custom-class line-top"
          :stroke-width="3"
        />
        Tải lại
      </button>
      <button
        v-if="actions.includes('createBtn') && actionsPermission[0]"
        class="compact ui button btn-primary text-height"
        @click="onAddCommom"
      >
        <plus-icon
          size="12"
          class="custom-class line-top"
          :stroke-width="3"
        />
        Thêm mới
      </button>
      <button
        v-if="actions.includes('ayncBtn')"
        class="compact ui button btn-primary"
        @click="onAsyncData"
      >
        <activity-icon
          size="12"
          class="custom-class line-top"
          :stroke-width="3"
        />
        Đồng bộ
      </button>
      <button
        v-if="actions.includes('editorProfileBtn')"
        class="compact ui button btn-primary"
        :class="isDisabelEditBtn ? 'disabled' : ''"
        @click="onEditorProfile"
      >
        Cập nhật kiểu hồ sơ
      </button>
      <button
        v-if="actions.includes('editorFormBtn')"
        class="compact ui button btn-primary"
        :class="isDisabelEditBtn ? 'disabled' : ''"
        @click="onEditorForm"
      >
        Cập nhật biểu mẫu
      </button>
      <button
        v-if="actions.includes('editorPaperBtn')"
        class="compact ui button btn-primary"
        :class="isDisabelEditBtn ? 'disabled' : ''"
        @click="onEditorPaper"
      >
        Cập nhật giấy tờ
      </button>
      <button
        v-if="actions.includes('answerBtn')"
        class="compact ui button btn-primary"
        :class="isDisabelEditBtn ? 'disabled' : ''"
        @click="onAddAnswerCommom"
      >
        Trả lời
      </button>
      <button
        v-if="actions.includes('detailBtn')"
        class="compact ui button btn-primary"
        :class="isDisabelEditBtn ? 'disabled' : ''"
        @click="onAddCommom"
      >
        Chi tiết
      </button>
      <button
        v-if="actions.includes('deleteBtn') && isShowDelete && actionsPermission[0]"
        class="compact ui button btn-primary text-height"
        :class="selectedNumber === 0 ? 'disabled' : ''"
        @click="onDelete"
      >
        <trash-2-icon
          size="12"
          class="custom-class line-top"
          :stroke-width="3"
        />
        Xóa
      </button>
      <button
        v-if="actions.includes('pqmpBtn')"
        class="compact ui button btn-primary"
      >
        Phân quyền mẫu phiếu
      </button>
    </div>
  </div>
</template>
<script>
import addCssSemantic from '@/mixins/mixins'
import {
  RefreshCwIcon,
  Trash2Icon,
  PlusIcon,
  ActivityIcon,
} from 'vue-feather-icons'

export default {
  components: {
    RefreshCwIcon,
    Trash2Icon,
    PlusIcon,
    ActivityIcon,
  },
  mixins: [addCssSemantic],
  props: {
    actions: {
      type: Array,
      default: () => ['createBtn', 'editorBtn', 'deleteBtn'],
    },
    selectedNumber: {
      type: Number,
      default: 0,
    },
    isShowDelete: {
      type: Boolean,
      default: true,
    },
    actionsPermission: {
      type: Array,
      default: () => [true, true],
    },
  },
  data() {
    return {
      searchInput: null,
    }
  },
  computed: {
    isDisabelEditBtn() {
      return this.$isDisabelBtn !== 1
    },
    isDisabelDeleteBtn() {
      return this.$isDisabelBtn < 1
    },
  },
  created() {
    this.$isDisabelBtn = 0
  },
  methods: {
    onRefreshPage() {
      this.$emit('refresh-page')
    },
    onAddCommom() {
      this.$emit('add-item')
    },
    onDelete() {
      if (this.selectedNumber >= 1) {
        this.$emit('delete-item')
      }
    },
    onSearchCommon() {
      this.$emit('onSearchCommon', this.searchInput)
    },
    onEditorProfile() {
      this.$emit('onEditorProfile')
    },
    onEditorForm() {
      this.$emit('onEditorForm')
    },
    onEditorPaper() {
      this.$emit('onEditorPaper')
    },
    onDeleteCommom() {
      this.$emit('deleteCommom')
    },
    onAddAnswerCommom() {
      this.$emit('add-answer-commom')
    },
    onAsyncData() {
      this.$emit('async-data')
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-wrap {
  button {
    margin: 2px;
  }
}
</style>
