<template>
  <div>
    <vue-good-table
      ref="vbTables"
      mode="remote"
      class="table-text-black"
      :columns="columns"
      :rows="displayedFiles"
      :pagination-options="{
        enabled: pagination,
      }"
      :select-options="{
        enabled: selectable,
        selectOnCheckboxOnly: true,
        selectionInfoClass: customClass,
        selectionText: 'dòng được chọn',
        clearSelectionText: 'bỏ chọn',
        disableSelectInfo: true,
      }"
      :sort-options="{
        enabled: false
      }"
      :row-style-class="rowStyle"
      @on-selected-rows-change="selectionChanged"
      @on-column-filter="setColumnFilter"
      @hook:mounted="addEventFilter"
    >
      <div
        slot="emptystate"
        class="text-center"
      >
        {{ isLoading ? 'Không có dữ liệu' : 'Đang tải dữ liệu' }}
      </div>
      <template
        slot="column-filter"
        slot-scope="props"
      >
        <slot
          name="custom-filter"
          :props="props"
        />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <slot
          name="custom-row"
          :props="props"
        />
        <span v-if="props.column.field == 'status' && customStatus">
          <span v-if="props.row.status">Hoạt động</span>
          <span v-else>Ngưng hoạt động</span>
        </span>
        <span v-else-if="props.column.field == 'tanXuat'">
          <span v-if="props.row.tanXuat === 2">Có thường gặp</span>
          <span v-else>Không thường gặp</span>
        </span>
        <span v-else-if="props.column.field == 'actions'">
          <slot
            name="actions"
            :props="props"
          >
            <div class="d-flex justify-content-center">
              <edit-icon
                v-if="checkEdit"
                v-b-tooltip.hover.v-secondary
                title="Cập nhật"
                size="16"
                class="custom-class cursor-pointer mr-1"
                @click="updateCommom(props.row)"
              />
              <slot
                name="action"
                :props="props"
              />
              <trash-2-icon
                v-if="checkDelete"
                v-b-tooltip.hover.v-secondary
                title="Xoá"
                size="16"
                class="custom-class cursor-pointer"
                @click="deleteCommom(props.row)"
              />
            </div>
          </slot>
        </span>
        <span v-else-if="props.column.field == 'STT'">
          {{ rowNumber(props) }}
        </span>
      </template>
      <template
        slot="pagination-bottom"
      >
        <div
          class="d-flex justify-content-between mt-1"
        >
          <div class="d-flex align-items-center">
            <div
              v-if="rows && rows.length > 0"
              class="mr-2"
            >
              Hiển thị từ {{ from }} đến {{ to }} trên {{ total }} bản ghi
            </div>
            <div class="d-none d-sm-block mr-1">
              <b-form-select
                v-model="pageSize"
                :options="pageSizeOptions"
                @change="onPageChange"
              />
            </div>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageSize"
              first-number
              last-number
              prev-text="Trước"
              next-text="Tiếp"
            />
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import {
  BPagination,
  BFormSelect,
  VBTooltip,
} from 'bootstrap-vue'
import { EditIcon, Trash2Icon } from 'vue-feather-icons'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    EditIcon,
    Trash2Icon,
  },
  props: {
    // Cột header truyền vào table
    columns: {
      type: Array,
      default: () => [],
    },
    // Danh sách truyền vào table
    rows: {
      type: Array,
      default: () => [],
    },
    // Tổng toàn bộ bản ghi của danh sách
    total: {
      type: Number,
      default: 0,
    },
    // Xem data đã load ra chưa để hiển thị vào danh sách
    isLoading: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    customStatus: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: String,
      default: '',
    },
    rowStyle: {
      type: Function,
      default: () => {
      },
    },
    actionsPermission: {
      type: Array,
      default: () => [true, true],
    },
    isPaginationClient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      pageSizeOptions: [10, 20, 50, 100, 200],
      pageSize: 10,
      columnFilter: {},
      loadAPI: false,
    }
  },
  computed: {
    displayedFiles() {
      const rowPage = this.rows
      if (this.isPaginationClient) {
        const startIndex = (this.currentPage - 1) * this.pageSize
        const endIndex = startIndex + this.pageSize
        return rowPage.slice(startIndex, endIndex)
      }
      return rowPage
    },
    from() {
      return this.currentPage * this.pageSize - this.pageSize + 1
    },
    to() {
      return this.total < this.currentPage * this.pageSize
        ? this.total
        : this.currentPage * this.pageSize
    },
    checkEdit() {
      if (this.actionsPermission && this.actionsPermission.length > 0) {
        return this.actionsPermission[0]
      }
      return true
    },
    checkDelete() {
      if (this.actionsPermission && this.actionsPermission.length > 0) {
        return this.actionsPermission[1]
      }
      return true
    },
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.lastPage = oldVal
      this.loadAPI = true
      this.$emit('page-change')
    },
    rows: {
      handler() {
        this.loadAPI = false
      },
      deep: true,
    },
  },
  methods: {
    rowNumber(row) {
      return row.index + (((this.loadAPI ? this.lastPage : this.currentPage) - 1) * this.pageSize) + 1
    },
    onPageChange() {
      this.$nextTick(() => {
        this.$emit('page-change')
      })
    },
    getCurrentPage() {
      return {
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
      }
    },
    setColumnFilter(newProps) {
      this.columnFilter = newProps.columnFilters
    },
    onColumnFilter() {
      this.$emit('column-filter', this.columnFilter)
    },
    addEventFilter() {
      this.$nextTick(() => {
        setTimeout(() => {
          const els = document.getElementsByClassName('vgt-input')
          els.forEach(el => {
            el.addEventListener('keyup', e => {
              if (e.key === 'Enter') {
                this.onColumnFilter()
              }
            })
          })
        }, 1000)
      })
    },
    selectionChanged(selectedItem) {
      this.$emit('selected-item', selectedItem.selectedRows)
    },
    updateCommom(data) {
      this.$emit('update-item', data)
    },
    deleteCommom(data) {
      this.$emit('delete-item', data)
    },
    resetCurrentPage(num) {
      this.currentPage = num
    },
  },
}
</script>
