const addCssSematic = {
  created() {
    this.loadScripts()
  },
  methods: {
    loadScripts() {
      this.renderExternalScript('/assets/workflow/js/jquery.js').onload = () => {}
      this.renderExternalScript('/assets/workflow/js/semantic.min.js').onload = () => {}
      this.renderExternalLink('/assets/workflow/css/semantic.min.css').onload = () => {}
    },
    renderExternalLink(href) {
      const link = document.createElement('link')
      link.type = 'text/css'
      link.href = href
      link.rel = 'stylesheet'
      document.body.appendChild(link)
      return link
    },
    renderExternalScript(src) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = src
      script.async = true
      script.defer = true
      document.body.appendChild(script)
      return script
    },
  },
}

export default addCssSematic
